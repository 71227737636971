import React from 'react';
import tw from 'twin.macro';
import { graphql } from 'gatsby';
import CategoryPageLayout from '../../components/CategoryPageLayout';
import { Inner, headerInnerStyles } from '../../components';
import FilterProvider from '../../utils/hooks/use-filter';

const TITLE = 'Company Cases on Doing Digital Differently';
const DESCRIPTION =
  'Here you can find all our blog articles, founder interviews & tech talents portraits.';
const SEO_DESCRIPTION =
  'Do you want to learn why Danish startups always get international attention from investors? Immerse yourself in one of many successful startup stories.';

const CompanyCasesPage = ({ data }) => {
  const companyCases = data.allDatoCmsCompanyCase.edges.map((companyCase) => companyCase.node);
  return (
    <CategoryPageLayout
      title={TITLE}
      description={DESCRIPTION}
      seoDescription={SEO_DESCRIPTION}
      posts={companyCases}
      slug="company-cases"
    />
  );
};

export default CompanyCasesPage;

export const companyCasesQuery = graphql`
  {
    allDatoCmsCompanyCase(sort: { fields: meta___firstPublishedAt, order: DESC }) {
      edges {
        node {
          title
          slug
          ribbonCaption
          featuredMedia {
            gatsbyImageData(
              placeholder: BLURRED
              imgixParams: { crop: "focalpoint", fit: "crop", w: "615", h: "450" }
            )
          }
          contentNode {
            childMarkdownRemark {
              excerpt
            }
          }
          model {
            name
          }
          date(formatString: "DD.MM.YYYY")
          dateTime: date(formatString: "YYYY.MM.DD")
          sortDate: date(formatString: "YYYYMMDD")
          ecospheres {
            label
          }
        }
      }
    }
  }
`;
